import React from 'react';

import cn from 'classnames';
import Button from '../Buttons/PrimaryButton';
import Input from '../Input';

import style from './Profile.scss';
// import NavigationAuth from './NavigationAuth';
// import NavigationNonAuth from './atoms/NavigationNonAuth';

const Profile = ({ authUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [values, setValues] = React.useState({});

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // setValues({
    //   ...values,
    //   [name]: value,
    // });
  };

  const handleSubmit = async (e) => {
    
  };
  return (
    <>
      <div className={cn('row', style.profile)}>
        <div className="col-12">
          <h1>Profile</h1>
          <h2> {authUser.email}</h2>
        </div>

        <>
          <div className="col-12">
            <div>
              <>
                <p>
                  {authUser.displayName}
                </p>
              </>
              <form className={style.inputs} onSubmit={handleSubmit}>
                <Input
                  name="email"
                  value={values?.email}
                  labelName="Email"
                  onChange={handleInputChange}
                  defaultValue={authUser.email}
                />
                <Input
                  name="name"
                  value={values?.name}
                  labelName="Name"
                  onChange={handleInputChange}
                  defaultValue={authUser.response}
                />
                <input type="hidden" name="id" value={authUser.uid} />
                <Button isDisabled type="submit" data-id={authUser.id} isLoading={isLoading}>
                  Update
                </Button>
              </form>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Profile;
