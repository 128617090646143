import React, { ComponentProps } from 'react';

import { LoadingDots } from '../LoadingDots';

import { Button } from './Button';

import style from './PrimaryButton.scss';

import cn from 'classnames';

export function PrimaryButton({
  children,
  isLoading,
  isDisabled,
  className,
  ...props
}: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      className={cn(className, style.primary)}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {isLoading ? <LoadingDots /> : children}
    </Button>
  );
}

export default PrimaryButton;
